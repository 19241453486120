import { DB_STRING_MAX } from '../constants/database-constants';
// no spaces allowed
export const USERNAME_REGEX = /^[\w._~-\u0400-\u04FF]*$/; // english word chars, numbers, latin accented chars
export const REPLACE_USERNAME_SPECIAL_CHARS = /[^a-zA-Z]/g;
const USERNAME_MAX_LENGTH = 75;
const BLADEBINGE_REGEX = /(bladebinge|binge|knafsale|knafsaleofficial)/;
const DISALLOWED_CHARS = ['×', '÷']; // these are allowed by the extended alphabet/accents regex and we don't want them

const nameContainsBladebinge = (name: string = '') =>
    BLADEBINGE_REGEX.test(name.toLowerCase().replaceAll(REPLACE_USERNAME_SPECIAL_CHARS, ''));

const hasDisallowedCharacters = (name: string = '') =>
    DISALLOWED_CHARS.reduce<boolean>((foundDisallowed, badChar) => {
        if (foundDisallowed || name.indexOf(badChar) > 0) {
            return true;
        }

        return foundDisallowed;
    }, false);

const hasAllowCharsOnly = (name: string = '') => USERNAME_REGEX.test(name);

const isSafeLength = (name: string = '') =>
    name.length > 2 && name.length <= DB_STRING_MAX && name.length <= USERNAME_MAX_LENGTH;

export const validateUsername = (username: string = ''): boolean =>
    !nameContainsBladebinge(username) &&
    hasAllowCharsOnly(username) &&
    isSafeLength(username) &&
    !hasDisallowedCharacters(username);
