import React from 'react';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';

const WHITE_LOGO = '/logos/bladebinge_logo_white_230w_80h.png';

const StyledFormLogo = styled('img')(({ theme }) => ({
    width: '100px',
    marginBottom: 0
}));

export const FormLogo = () => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center" sx={{ my: 2 }}>
            <StyledFormLogo alt={t('navigation.logo_alt')} src={getCdnUrl(WHITE_LOGO)} />
        </Grid>
    );
};
