import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';
import { StripePaymentInfo } from '@bladebinge/types';
import { useStateSafe } from '../hooks/use-state-safe';
import { useMe } from './me/me-context';

interface PurchaseInfoContext {
    agreeToTerms?: boolean;
    billToAddressId?: string;
    confirmedPurchaseId?: string;
    buyerId?: string;
    guestEmail?: string;
    includeInsurance: boolean;
    isGuest?: boolean;
    newRegistrationBuyerId?: string;
    paymentInfo?: StripePaymentInfo;
    paymentIntentId?: string;
    shipToAddressId?: string;
    stripe?: Stripe;
    usePaymentFinancing?: boolean;

    setBillToAddressId: (v: string) => void;
    setConfirmedPurchaseId: (v: string) => void;
    setIncludeInsurance: (v: boolean) => void;
    setIsGuest: (v: boolean) => void;
    setGuestEmail: (v: string) => void;
    setNewRegistrationBuyerId: (v: string) => void;
    setPaymentInfo: (v: StripePaymentInfo) => void;
    setPaymentIntentId: (v: string) => void;
    setShipToAddressId: (v: string) => void;
    setStripe: (s: Stripe) => void;
    setUsePaymentFinancing: (v: boolean) => void;
}

const purchaseInfoContext = createContext<PurchaseInfoContext>({
    includeInsurance: true,
    setBillToAddressId(id: string) {},
    setConfirmedPurchaseId(id: string) {},
    setGuestEmail(id: string) {},
    setIncludeInsurance(v: boolean) {},
    setIsGuest(v: boolean) {},
    setNewRegistrationBuyerId(v: string) {},
    setPaymentInfo(v: StripePaymentInfo) {},
    setPaymentIntentId(id: string) {},
    setShipToAddressId(id: string) {},
    setStripe(s: Stripe) {},
    setUsePaymentFinancing(v: boolean) {}
});

const { Provider } = purchaseInfoContext;

export const PurchaseInfoContextProvider = ({ children }: { readonly children: React.ReactNode }) => {
    const { id: loggedInUser } = useMe();

    const { agreeToTerms, buyerId } = useMemo(
        () => ({
            agreeToTerms: Boolean(loggedInUser),
            buyerId: loggedInUser ?? undefined
        }),
        [loggedInUser]
    );

    // lowercase p denoting "private"
    const [includeInsurance, setIncludeInsurance] = useState<boolean>(true);
    const [isGuest, setIsGuest] = useState<boolean>(!loggedInUser);
    const [pusePaymentFinancing, setPusePaymentFinancing] = useState<boolean>(false);
    const [newRegistrationBuyerId, setNewRegistrationBuyerId] = useStateSafe<string>('');
    const [pbillToAddressId, setPbillToAddressId] = useState<string>();
    const [pconfirmedPurchaseId, setPconfirmedPurchaseId] = useState<string>();
    const [pguestEmail, setPguestEmail] = useState<string>();
    const [ppaymentInfo, setPpaymentInfo] = useState<StripePaymentInfo>();
    const [ppaymentIntentId, setPpaymentIntentId] = useState<string>();
    const [pshipToAddressId, setPshipToAddressId] = useState<string>();
    const [pstripe, setPstripe] = useState<Stripe>();

    useEffect(() => {
        if (loggedInUser) {
            setIsGuest(false);
        }
    }, [loggedInUser]);

    const { billToAddressId, setBillToAddressId } = useMemo(
        () => ({
            billToAddressId: pbillToAddressId,
            setBillToAddressId: setPbillToAddressId
        }),
        [pbillToAddressId]
    );

    const { confirmedPurchaseId, setConfirmedPurchaseId } = useMemo(
        () => ({
            confirmedPurchaseId: pconfirmedPurchaseId,
            setConfirmedPurchaseId: setPconfirmedPurchaseId
        }),
        [pconfirmedPurchaseId]
    );

    const { guestEmail, setGuestEmail } = useMemo(
        () => ({
            guestEmail: pguestEmail,
            setGuestEmail: setPguestEmail
        }),
        [pguestEmail]
    );

    const { paymentInfo, setPaymentInfo } = useMemo(
        () => ({
            paymentInfo: ppaymentInfo,
            setPaymentInfo: setPpaymentInfo
        }),
        [ppaymentInfo]
    );

    const { paymentIntentId, setPaymentIntentId } = useMemo(
        () => ({
            paymentIntentId: ppaymentIntentId,
            setPaymentIntentId: setPpaymentIntentId
        }),
        [ppaymentIntentId]
    );

    const { shipToAddressId, setShipToAddressId } = useMemo(
        () => ({
            shipToAddressId: pshipToAddressId,
            setShipToAddressId: setPshipToAddressId
        }),
        [pshipToAddressId]
    );

    const { stripe, setStripe } = useMemo(
        () => ({
            stripe: pstripe,
            setStripe: setPstripe
        }),
        [pstripe]
    );

    const setUsePaymentFinancing = (v: boolean) => {
        // clear stripe payment intent info if we change financing choice
        if (v !== pusePaymentFinancing) {
            setPpaymentIntentId(undefined);
            setPpaymentInfo(undefined);
            setPusePaymentFinancing(v);
        }
    };

    return (
        <Provider
            value={{
                agreeToTerms,
                billToAddressId,
                buyerId,
                confirmedPurchaseId,
                includeInsurance,
                isGuest,
                guestEmail,
                newRegistrationBuyerId,
                paymentInfo,
                paymentIntentId,
                shipToAddressId,
                stripe,
                usePaymentFinancing: pusePaymentFinancing,

                // setters
                setBillToAddressId,
                setConfirmedPurchaseId,
                setGuestEmail,
                setIncludeInsurance,
                setIsGuest,
                setNewRegistrationBuyerId,
                setPaymentInfo,
                setPaymentIntentId,
                setShipToAddressId,
                setStripe,
                setUsePaymentFinancing
            }}
        >
            {children}
        </Provider>
    );
};

export const usePurchaseInfoContext = () => useContext(purchaseInfoContext);
