import React from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import RateReview from '@mui/icons-material/RateReview';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MessageIcon from '@mui/icons-material/Message';
import { FormLogo } from './FormLogo';

interface FormHeaderProps {
    readonly icon?: 'account' | 'lock' | 'message' | 'rate' | 'shipping';
    readonly instructions?: string;
    readonly showLogo?: boolean;
    readonly title: string;
}

const StyledAvatar = Avatar;

export const FormHeader = ({ icon, instructions, showLogo = true, title }: FormHeaderProps) => {
    let muiIcon = null;
    switch (icon) {
        case 'account': {
            muiIcon = <AccountCircle />;
            break;
        }

        case 'lock': {
            muiIcon = <LockOutlinedIcon />;
            break;
        }

        case 'message': {
            muiIcon = <MessageIcon />;
            break;
        }

        case 'rate': {
            muiIcon = <RateReview />;
            break;
        }

        case 'shipping': {
            muiIcon = <LocalShippingIcon />;
            break;
        }

        default: {
            break;
        }
    }

    return (
        <>
            {showLogo && <FormLogo />}
            <Grid container spacing={1} justifyContent="center">
                <Grid
                    container
                    justifyContent="center"
                    size={{
                        sm: 12,
                        xs: 12,
                        lg: 12,
                        xl: 12
                    }}
                >
                    {muiIcon && (
                        <Grid>
                            <StyledAvatar
                                color="primary"
                                sx={{
                                    my: 0.25,
                                    mr: 1,
                                    backgroundColor: 'primary.main'
                                }}
                            >
                                {muiIcon}
                            </StyledAvatar>
                        </Grid>
                    )}
                    <Grid>
                        <Typography display="inline" variant="h4">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid>{instructions && <Typography variant="body1">{instructions}</Typography>}</Grid>
            </Grid>
        </>
    );
};
