import { DB_STRING_MAX } from '../constants/database-constants';

// at least 7 characters no spaces
export const PASSWORD_REGEX = /^[\w!#$%&()*-@^]{7,}$/;

export const validatePassword = (rawPassword: string): boolean => {
    const password = rawPassword.trim();

    if (password.length > DB_STRING_MAX) {
        return false;
    }

    return PASSWORD_REGEX.test(password);
};

export const validatePasswordMatch = (rawPassword: string, rawConfirmedPassword: string): boolean => {
    const password = rawPassword.trim();
    const confirmedPassword = rawConfirmedPassword.trim();

    if (password !== confirmedPassword) {
        return false;
    }

    return PASSWORD_REGEX.test(password);
};
